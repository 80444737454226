import React from 'react';
import * as THREE from 'three';

function ContainerObject(props) {
  const scale = props.scale? props.scale: 1;
  const length = props.length * scale;
  const width = props.width * scale;
  const height = props.height * scale;

  const geometry = new THREE.BoxGeometry(length, height, width);

  function drawBase() {
    if (props.showBase) {
      const baseWidth = props.baseWidth? props.baseWidth: 1000;
      const baseDepth = props.baseDepth? props.baseDepth: 1000;
      const baseHeight = 2;

      return (
        <mesh position={[0, -(height / 2) - baseHeight, 0]} >
          <boxGeometry args={[baseWidth, baseHeight, baseDepth]} />
          <meshLambertMaterial color={'#fff'} opacity={0.5} transparent={true} />
        </mesh>
      );
    }
  }

  return (
    <>
    <lineSegments>
      <edgesGeometry args={[geometry]} />
      <lineBasicMaterial color={0x000000} />
    </lineSegments>
    {drawBase()}
    </>
  );
}

export default ContainerObject;
