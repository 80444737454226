import React, {useEffect, useState} from 'react';
import {useFrame} from '@react-three/fiber'
import * as THREE from 'three';

function BoxObject(props) {
  const scale = props.scale? props.scale: 1;
  const length = props.length * scale;
  const width = props.width * scale;
  const height = props.height * scale;
  const x = props.x * scale + (length / 2) - props.offsets.length * scale;
  const y = props.y * scale + (width / 2) - props.offsets.width * scale;
  const z = props.z * scale + (height / 2) - props.offsets.height * scale;

  const boxMesh = React.useRef();
  const boxLines = React.useRef();
  const [active, setActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const geometry = new THREE.BoxGeometry(length, height, width);
  const color = props.color;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, props.delay);
    return () => clearTimeout(timer);
  }, []);

  useFrame(({ clock }) => {
    if (isVisible && props.moveObject) {
      props.moveObject(props.moving, boxMesh.current, y);
      props.moveObject(props.moving, boxLines.current, y);
    }
  });

  function showlineSegments() {
    if (isVisible) {
      return <lineSegments ref={boxLines} position={[x, z, y]} >
        <edgesGeometry args={[geometry]} />
        <lineBasicMaterial color={0x000} />
      </lineSegments>
    }
  }

  return (
    <>
    <mesh position={[x, z, y]}
      visible={isVisible}
      onClick={e => {
        e.stopPropagation();
        if (props.onClick) props.onClick();
      }}
      onPointerOver={e => {
        e.stopPropagation();
        setActive(true);
        if (props.onPointerOver) props.onPointerOver();
      }}
      onPointerOut={e => {
        e.stopPropagation();
        setActive(false);
        if (props.onPointerOut) props.onPointerOut();
      }}
      ref={boxMesh} >
      <boxGeometry args={[length - 1, height - 1, width - 1]} />
      <meshLambertMaterial color={active ? 'white': color}/>
    </mesh>
    {showlineSegments()}
    </>
  );
}

export default BoxObject;
