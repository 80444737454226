import {React} from "react";

function Header(props) {

  let navClassName = "flex flex-col-reverse text-left mt-5 mb-2 md:mt-0 md:mb-0 md:flex-grow md:h-16 md:flex-row-reverse text-gray-600";

  return (
    <header className="py-3 border-b">
      <div className="container mx-auto relative">
        <div className="text-2xl mt-2 text-red-800 text-center">
        <span className="ml-3" >
          Algo Exchange {props.title}
        </span>
        </div>
      </div>
    </header>
  );
}

export default Header;
