import React, {useState, useEffect} from 'react';
import {useFrame} from '@react-three/fiber'
import * as THREE from 'three';

import BoxObject from './BoxObject';

const loader = new THREE.TextureLoader();
const palletMaterials = [
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-1.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-1.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
  new THREE.MeshBasicMaterial({map: loader.load('texture/pallet-2.png')}),
];

function PalletObject(props) {
  const scale = props.scale? props.scale: 1;
  const length = props.length * scale;
  const width = props.width * scale;
  // const height = props.height * scale;
  const pHeight = props.palletHeight * scale;

  const x = props.x * scale + (length / 2) - props.offsets.length * scale;
  const y = props.y * scale + (width / 2) - props.offsets.width * scale;
  const z = props.z * scale + (pHeight / 2) - props.offsets.height * scale;

  const palletMesh = React.useRef();
  const palletLines = React.useRef();
  const [moving, setMoving] = useState(false);

  const geometry = new THREE.BoxGeometry(length, pHeight, width);
  const color = props.color;

  const step = 2;
  const maxOffset = Math.floor(props.userData.container.width * scale / 2) * 2;
  const direction = props.y <= (props.userData.container.width / 3)? -1 : 1;

  function moveObject(state, object, y) {
    if (state) {
      if (Math.abs(object.position.z - y) < maxOffset) {
        object.translateZ(direction * step);
      }
    } else {
      if (Math.abs(object.position.z - y) > step) {
        object.translateZ(-direction * step);
      }
    }
  }

  useFrame(({ clock }) => {
    moveObject(moving, palletMesh.current, y);
    moveObject(moving, palletLines.current, y);
  });

  function onPalletClick() {
    setMoving(!moving);
    if (props.onPalletClick) {
      props.onPalletClick(props.userData.pallet);
    }
  }

  function showBoxes() {
    if (props.boxes) {
      const boxes = props.boxes;
      const boxOffsets = {
        length: length / 2 - x,
        width: width / 2 - y,
        height: - z,
      };

      const swap = props.width > props.length;

      return boxes.map((box) => ({
        id: box.id,
        length: swap? box.width * scale: box.length * scale,
        width: swap? box.length * scale: box.width * scale,
        height: box.height * scale,
        x: swap? box.y * scale: box.x * scale,
        y: swap? box.x * scale: box.y * scale,
        z: box.z * scale + (pHeight / 2),
      })).map((box, i) => <BoxObject key={i} {...box} offsets={boxOffsets}
          scale={1} color={color} moving={moving} moveObject={moveObject}
          onPointerOver={() => {
            if (props.onBoxPointerOver) {
              props.onBoxPointerOver(box);
            }
          }}
          onClick={onPalletClick} />);
    }
  }

  let timeoutId;
  useEffect(() => {
    if (timeoutId) clearTimeout(timeoutId);
    if (moving) {
      timeoutId = setTimeout(() => setMoving(false), 10000);
    }
    return () => {if (timeoutId) clearTimeout(timeoutId)};
  }, [moving]);

  return (
    <>
    <mesh position={[x, z, y]} material={palletMaterials}
      onClick={e => {
        e.stopPropagation();
        onPalletClick();
      }}
      onPointerOver={e => {
        e.stopPropagation();
      }}
      ref={palletMesh} >
      <boxGeometry args={[length - 1, pHeight - 1, width - 1]} />
    </mesh>
    <lineSegments position={[x, z, y]} ref={palletLines} >
      <edgesGeometry args={[geometry]} />
      <lineBasicMaterial color={0x000} />
    </lineSegments>
    {showBoxes()}
    </>
  );
}

export default PalletObject;
